import { getImage } from "gatsby-plugin-image"
import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Album from '../../components/photogallery/album'
import GalleryLayout from '../../components/photogallery/galleryLayout'
import { graphql } from 'gatsby'

class GalleryDirectory extends React.Component {
  render() {
    const { data } = this.props
    const b14 = getImage(data.b14.childImageSharp.gatsbyImageData)
    const b15 = getImage(data.b15.childImageSharp.gatsbyImageData)
    const b17 = getImage(data.b17.childImageSharp.gatsbyImageData)
    const jt20 = getImage(data.jt20.childImageSharp.gatsbyImageData)
    const smnp = getImage(data.smnp.childImageSharp.gatsbyImageData)
    const yos = getImage(data.yos.childImageSharp.gatsbyImageData)
    const sequoia = getImage(data.sequoia.childImageSharp.gatsbyImageData)
    const sequoia2 = getImage(data.sequoia2.childImageSharp.gatsbyImageData)

    return (
      <GalleryLayout>
        <Container>
          <Row>
          <Album
              image={sequoia2}
              imageRight={false}
              title="Kings Canyon and Sequoia National Parks, 2022"
              description="For Memorial day weekend we had a great time camping with friends in Kings Canyon 
              and Sequoia National Parks. We spent our time in Kings Canyon exploring the rivers, lakes, and caverns, and 
              stopped at the famous General Sherman tree in Seqouia National Park."
              info="Photos by Selena Webb, Nick Torres, and Friends"
              page={'sequoia2'}
          ></Album>
          <Album
              image={sequoia}
              title="Kings Canyon and Sequoia National Parks, 2021"
              description="The 2021 season was particularly bad for wildfires, but fortunately Kings Canyon and Sequoia National Parks
              were able to re open parts of the forest in time for our trip. Even though most of the parks were closed, we were
              able to see Grant Grove and other place like Hospital Rock, which had Native American pictographs."
              info="Photos by Selena Webb and Nick Torres"
              page={'sequoia'}
            ></Album>
            <Album
              image={yos}
              imageRight={false}
              title="Yosemite National Park, 2021"
              description="This Labor Day Weekend we visited the famous Yosemite National Park, and it did not dissapoint!
              Sam, Selena, and myself had a great time exploring the valleys, Sequoia groves, and high altitude lakes. This is
              by far my favorite National Park yet!"
              info="Photos by Selena Webb and Nick Torres"
              page={'yosemite'}
            ></Album>
            <Album
              image={jt20}
              title="Joshua Tree National Park 2020"
              description="Selena and I take a quick trip to the desert to experience the dry heat and 
              amazing views that the park has to offer. "
              page={'jtnp'}
              info={'Photos by Selena Webb and Nick Torres'}
            ></Album>
            <Album
              image={smnp}
              title="Smoky Mountains National Park 2018"
              description="Hiking along Rainbow Falls Trail, Gatlinburg Tennesee in late spring."
              page={'smnp'}
              imageRight={false}
              info={'Photos by Selena Webb and Nick Torres'}
            ></Album>
            <Album
              image={b17}
              title="Boundary Waters Canoe Area, 2017"
              description="Uncle Matt, Dan, and myself return to the Boundary Waters for our most difficult
                            trip yet. Trekking through moose territory and camping on our own island made this an
                            unforgettable experience. "
              info="Photos by Daniel Torres"
              page={'bwca17'}
            ></Album>
            <Album
              image={b15}
              imageRight={false}
              title="Boundary Waters Canoe Area, 2015"
              description="The Gang gets their feet wet as Dan and Sam join us for another trip to the
                            Boundary Waters Canoe Area. This remote trip proved to have some beautiful views and peaceful
                            campsites."
              info="Photos by Nick and Daniel Torres"
              page={'bwca15'}
            ></Album>
            <Album
              image={b14}
              imageRight={true}
              title="Boundary Waters Canoe Area, 2014"
              description="In this album my Uncle Matt and I explore the Boundary Waters Canoe Area in search
                            of big fish and wild blueberries. This week long paddle through Minnesota covered roughly
                            20 lakes in the eastern part of Superior National Forest."
              info="Shot with an IPhone 4 camera "
              page={'bwca14'}
            ></Album>
          </Row>
        </Container>
      </GalleryLayout>
    )
  }
}

export default GalleryDirectory

export const query = graphql`
  query GalleryQuery {
    b14: file(name: { eq: "b14" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    b15: file(name: { eq: "b15" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    b17: file(name: { eq: "b17" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    jt20: file(name: { eq: "j20" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    smnp: file(name: { eq: "sm" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    yos: file(name: { eq: "yos" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    sequoia: file(name: { eq: "sequ" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    sequoia2: file(name: { eq: "sequ2" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
  }
`
