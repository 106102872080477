import React from 'react'
import { Link, navigate } from 'gatsby'
import './style.scss'

class Header extends React.Component {
  constructor(props) {
    super(props)
  }

  navigateToGallery(){
    navigate("/galleryDirectory")
  }

  mobileMenu() {
    return (
     <button className="mobileMenu" onClick={this.navigateToGallery} >Albums</button>
    )
  }

  render() {
    let classNav = 'collapse navbar-collapse navbar-right navbar-main-collapse'

    return (
      <nav
        className="navbar navbar-custom "
        role="navigation"
        style={{
          opacity: '0.7',
        }}
      >
        <div
          className="container"
          style={{
            backgroundColor: 'black',
          }}
        >
          <div className="navbar-header">
            <Link className="navbar-brand page-scroll" to="/">
              <div>{'{ Nick Torres }'}</div>
            </Link>
            {this.mobileMenu()}
          </div>
          <div className={classNav}>
            <Link  to="/galleryDirectory">
              <ul className="nav navbar-nav" id="nav-links">
                  <li className="navbar-brand page-scroll" onClick={this.navigateToGallery}>Albums</li>
              </ul>
            </Link>
            {this.props.tripNumber}
          </div>
        </div>
      </nav>
    )
  }
}

export default Header
